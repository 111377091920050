import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Accordion from '../components/accordion';
import Cta from '../components/cta';

import Reveal from 'react-reveal/Reveal';

const IndexPage = () => (
  <Layout>
    <SEO title="subspace AI" />
    <div className="wrap">
      <Reveal effect="fadeInUpSlow">
        <h2 className="intro">We build and maintain <br/>AI-driven applications.</h2>
      </Reveal>
      <Reveal effect="fadeInUpSlow">
        <p>
          The hardest part of building an AI product is usually not the model. There are automated tools that give you the best model for your dataset, yet products involving artificial intelligence often fail. As many companies are learning the hard way, the real challenge is productionisation of AI.
        </p>
        <p>
          A huge amouny of software and infrastructure needs to be in place for reliably training and deploying models. Data needs to be gathered properly for it to be useful for models, and processed in a way which is scalable yet easy to change in the face of changing requirements. Models are created and tuned by data scientists, yet often deployed by engineers, which adds further complexity and delays to the process. A failure in any one of these areas can render your whole system useless, which is often costly to track down and fix.
        </p>
        <p>
          During the course of delivering various end-to-end AI solutions to our clients, subspace has developed a methodology which ensures smooth coordination between the different functions of data science, engineering and data acqusition. It is critical to have a process which allows your team to iterate on ideas fast without risking any downtime to your service.
        </p>
      </Reveal>
      <Cta />
    </div>
    <section className="services">
      <div className="wrap">
        <Accordion allowMultipleOpen>
            <div label='Analysis, process &#38; architecture design'>
              <p>
                The solution that works best for you depends a lot on your organisation. By looking at the different technical competencies, existing infrastructure and processes in your firm, we can propose an architecture and process that works best for you.
              </p>
            </div>
            <div label='Exploratory data analysis'>
              <p>
                Do you have some data and you think this could be useful somehow? Do you have a specific question you would like to anwer, or would just want to know what could be made of it? We can analyse your datasets by visualising, calculating useful statistics and training statistical / machine learning models on it to understand what insights or predictive value it may have.
              </p>
            </div>
            <div label='Infrastructure'>
              <p>
                We can help you build out the infrastructure needed to train and serve machine learning models and related services. This can be in your existing cloud provider such as AWS or GCP, on your premises, or even fully managed by ourselves in our cloud installation. We can provide support and maintenance to ensure your services run smoothly.
              </p>
            </div>
            <div label='Building data pipelines, big and small'>
              <p>
                Your data is probably distributed across databases of different shapes and sizes, CSV and JSON files in a S3 bucket, external services and so on. Relevant bits from different data sources need to be pulled together for any kind of model to use it. This needs to happen reliably, scalably, and in an automated fashion - either as batch-oriented pipelines, real-time streams or through some ETL tool.
              </p>
            </div>
            <div label='Developing, training and deploying machine learning models'>
              <p>
                Building a model is an iterative and continuous process. Initial, exploratory model selection and tuning is followed by productionising and deploying the model. Our process gives flexibility in the prototyping phase, yet ensures the exploratory work can be automatically deployed to production. Automated tests are performed on the model, before it is gradually rolled out to the world.
              </p>
            </div>
            <div label='Monitoring and maintaining machine learning models'>
              <p>
                Once a model is out, it needs to be monitored for latency, reliability, and accuracy. Statistics on the input data and predictions can flag cases where the data distribution has changed since training the model, which can cause a silent failure that needs to be resolved with additional training data. Continuously monitoring a range of KPIs will give a good sense of how the models are doing. A human-in-the-loop approach where certain difficult data points are sent to humans for verification will improve the output quality and give an estimate of a real-time accuracy of your models.
              </p>
            </div>
          </Accordion>
      </div>
    </section>
    <section className="technologies">
        <Reveal effect="fadeInUp">
          <div className="wrap">
              <ul>
                <h2>We mainly work with</h2>
                  <li><b>Languages:</b> Python, Clojure, JavaScript, Scala, Java</li>
                  <li><b>Clouds:</b> Amazon Web Services, Google Cloud Platform, Heroku</li>
                  <li><b>DevOps &amp; MLOps:</b> Terraform, Kubernetes, Kubeflow, Airflow</li>
                  <li><b>Deep learning:</b> PyTorch, TensorFlow, MXNet</li>
                  <li><b>Machine learning:</b> scikit-learn, xgboost, spacy</li>
                  <li><b>Big data:</b> Apache Spark (PySpark), Apache Beam, GCP Dataflow</li>
                  <li><b>Streaming data:</b> Apache Kafka, RabbitMQ, Redis</li>
                  <li><b>Web development:</b> reagent, re-frame, react, D3</li>
              </ul>
          </div>
        </Reveal>
      </section>

  </Layout>
)

export default IndexPage
