import React from "react"
import Reveal from 'react-reveal/Reveal';

const Cta = () => {
  return (
    <Reveal effect="fadeInUpSlow">
        <div className="cta">
            <h3>Get in touch!</h3>
            Send us an e-mail if you'd like to schedule a free consultation.<br/>
            <a href="mailto:info@subspace.ai">info@subspace.ai</a>
        </div>
    </Reveal>
  )
}

export default Cta
