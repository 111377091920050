import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Reveal from 'react-reveal/Reveal';


class AccordionSection extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Object).isRequired,
    isOpen: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  onClick = () => {
    this.props.onClick(this.props.label);
  };

  render() {
    const {
      onClick,
      props: { isOpen, label },
    } = this;

    return (
        <Reveal effect="fadeInUp">
            <div onClick={onClick} style={{ cursor: 'pointer' }} className="accordion__item">
                <div >
                <h2>{label}
                <div style={{ float: 'right' }}>
                    <span className={isOpen ? 'opened' : 'closed'}></span>
                </div></h2>
                </div>
                {isOpen && (
                <div>
                    {this.props.children}
                </div>
                )}
            </div>
        </Reveal>
    );
  }
}

export default AccordionSection;